<template>
    <div>
        <van-nav-bar
                placeholder
                fixed
                left-arrow
                :border='false'
                class="header"
                title="书籍详情"
                @click-left="$router.go(-1)">
            <template #left>
                <van-icon name="arrow-left" size="25" color="#0A1C33"/>
            </template>
        </van-nav-bar>
        <div class="details-relative">
            <div class="details-top">
                <p class="points_relative">
                    <img :src="detailsList.coverpic" alt="" class="book_img">
                    <span class="points_vip" v-if="detailsList.isvip =='1'">VIP</span>
                </p>
                <div>
                    <p class="title">{{detailsList.title}}</p>
                    <p class="Author_icon1">
                        <img src="../../assets/images/Author_icon1.png" alt="" class="Author_icon">
                        <span class="blue">{{detailsList.author}}</span>
                    </p>
                    <p class="subtitle">{{detailsList.iswz == 1 ?'连载中':'已完结'}}</p>
                </div>
            </div>
            <p><img src="../../assets/images/1.png" alt="" class="img-button"></p>
        </div>
        <div class="sentiment div">
            <div style="flex: 3">
                <p class="sentiment-title"><span class="number">{{detailsList.read}}</span>+</p>
                <p class="sentiment-subtitle">在读</p>
            </div>
            <div style="flex: 3">
                <p class="sentiment-title"><span class="number">{{detailsList.hots}}</span>+</p>
                <p class="sentiment-subtitle">人气</p>
            </div>
            <div class="subtitle-right">
                <p class="subtitle-right-title" v-if="detailsList.average_score != 0">{{detailsList.average_score}}分</p>
                <p class="subtitle-right-title" v-else></p>
                <div>
                    <van-rate v-model="detailsList.average_star" :icon="img" :void-icon="img1" size="13" readonly/>
                </div>
            </div>
        </div>
        <div class="sentiment-text div" v-html="detailsList.remark"></div>
        <div class="sentiment-type-list">
            <template v-for="item in detailsList.tag">
                <p class="sentiment-type">{{item}}</p>
            </template>
        </div>
        <div class="chapter-list" @click="directoryClick">
            <div class="chapter-right">
                <p class="chapter-left-title">查看目录</p>
                <p class="chapter-left-time">{{detailsList.iswz == 1 ?'连载中':'已完结'}}{{detailsList.allchapter}}章</p>
            </div>
            <div class="chapter-right">
                <p class="chapter-left-time">更新于{{detailsList.updated_at}}</p>
                <p>
                    <van-icon name="arrow" color="#BFC2CC" size="10"/>
                </p>
            </div>
        </div>
        <div class="comments-border">
            <div class="comments-top-list">
                <div class="comments-top">
                    <p class="chapter-left-title">评论区</p>
                    <p class="chapter-left-time">{{detailsList.total_comments}}条评论</p>
                </div>
                <div class="comments-top" @click="evaluationClick">
                    <p class="chapter-left-title">
                        <img src="../../assets/images/Write_icon.png" alt="" class="Write_icon"></p>
                    <p class="comments-blue">写评论</p>
                </div>
            </div>
            <div class="comments-swiper" v-if="detailsList.comments">
                <van-swipe :show-indicators="false">
                    <template v-for="(item,index) in detailsList.comments">
                        <van-swipe-item>
                            <div class="comments-list">
                                <p><img :src="item.avatar" alt="" class="head"></p>
                                <div style="width: 100%;">
                                    <div class="comments-div">
                                        <p class="comments-list-title">{{item.nickname}}</p>
                                        <div>
                                            <van-rate v-model="item.star" :icon="img" :void-icon="img1" size="13"
                                                      readonly/>
                                        </div>
                                    </div>
                                    <p class="comments-text">
                                        {{item.content}}
                                    </p>
                                    <div class="comments-div">
                                        <p class="comments-time">{{item.updated_at}}</p>
                                        <p class="comments-div-text"
                                           @click="do_likes(item,index)"
                                           :class="item.is_admire == 1?'text_active':''">
                                            <img src="../../assets/images/icon1.png" alt="" class="icon-img"
                                                 v-if="item.is_admire == 1">
                                            <img src="../../assets/images/icon.png" alt="" class="icon-img" v-else>
                                            {{item.likes}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </van-swipe-item>
                    </template>
                    <template v-if="detailsList.comments.length == 0">
                        <van-swipe-item>
                            <div class="comments-list comments-list-no">
                                暂无评论
                            </div>
                        </van-swipe-item>
                    </template>
                </van-swipe>
            </div>
            <p class="comments-button" @click="bookreviewClick">查看全部（{{detailsList.total_comments}}）</p>
        </div>
        <div class="comments-border">
            <div class="like">
                <p class="like-title">猜你喜欢</p>
                <p class="like-right" @click="change_guess">
                    换一换 <img src="../../assets/images/Switch_icon.png" alt="" class="Switch_icon">
                </p>
            </div>
            <div class="div">
                <div class="book-button">
                    <template v-for="item in detailsList.guess">
                        <div class="book-button-div" @click="detailsClick(item)">
                            <p>
                                <img :src="item.coverpic" alt="" class="book_img1">
                                <span class="points_vip" v-if="item.isvip =='1'">VIP</span>
                            </p>
                            <template v-if="item.isvip !='1'">
                                <p class="points" v-if="item.average_score != 0">{{item.average_score}}
                                    <span>分</span>
                                </p>
                            </template>
                            <p class="book-title">{{item.title}}</p>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div class="reading-fixed">
            <div class="reading-list">
                <p class="reading-title" :class="detailsList.is_shelve==1?'reading-title_active':''" @click="shelve">
                    <img src="../../assets/images/Plus_icon__visited.png"
                         alt="" class="Plus_icon" v-if="detailsList.is_shelve==1">
                    <img src="../../assets/images/Plus_icon.png"
                         alt="" class="Plus_icon" v-else>
                    {{detailsList.is_shelve == 1?"已在书架":"加入书架"}}
                </p>
                <p class="reading" @click="readingClick">
                    立即阅读
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Details",
        data() {
            return {
                img: require("../../assets/images/Stars_icon.png"),
                img1: require("../../assets/images/Stars_icon_50.png"),
                detailsList: [],
                id: this.$route.query.id,
            }
        },
        created() {

            this.Getanime_id()
        },
        methods: {
            // 加入书架
            shelve() {
                if (this.detailsList.is_shelve == 1) {
                    this.$axios.get('/api/bookstores/remove_from_shelve?id=' + this.id)
                        .then(res => {
                            if (res.data.code == 200) {
                                this.$toast(res.data.message);
                                this.detailsList.is_shelve = 0
                            }
                        })
                        .catch(err => {
                            this.$toast(err.response.data.message);
                        })
                } else {
                    this.$axios.get('/api/bookstores/add_to_shelve?anime_id=' + this.id)
                        .then(res => {
                            if (res.data.code == 200) {
                                this.$toast(res.data.message);
                                this.detailsList.is_shelve = 1
                            }
                        })
                        .catch(err => {
                            this.$toast(err.response.data.message);
                        })
                }

            },
            // 点赞
            do_likes(item, index) {
                if (item.is_admire == 0) {
                    this.$axios.post('/api/comment/do_likes', {
                        comment_id: item.id
                    })
                        .then(res => {
                            if (res.data.code == 200) {
                                this.$toast(res.data.message);
                                this.detailsList.comments[index].is_admire = 1
                                this.detailsList.comments[index].likes++
                            }
                        })
                        .catch(err => {
                            this.$toast(err.response.data.message);
                        })
                } else {
                    this.$axios.post('/api/comment/cancel_likes', {
                        comment_id: item.id
                    })
                        .then(res => {
                            if (res.data.code == 200) {
                                this.$toast(res.data.message);
                                this.detailsList.comments[index].is_admire = 0
                                this.detailsList.comments[index].likes--
                            }
                        })
                        .catch(err => {
                            this.$toast(err.response.data.message);
                        })
                }
            },
            // 换一换
            change_guess() {
                this.$axios.post('/api/bookstores/change_guess', {
                    anime_id: this.id
                })
                    .then(res => {
                        this.detailsList.guess = res.data.data
                    })
            },
            Getanime_id() {
                this.$axios.get('/api/bookstores/anime_info?anime_id=' + this.id)
                    .then(res => {
                        var res = res.data.data
                        res.average_star = Number(res.average_star)

                        if (res.tag != null) {
                            res.tag = res.tag.split('|')
                        }
                        res.desc = this.html_encode(res.desc)
                        this.detailsList = res
                    })
            },
            html_encode(str) {
                var s = "";
                if (str.length == 0) return "";
                s = str.replace(/&/g, "&amp;");
                s = s.replace(/</g, "&lt;");
                s = s.replace(/>/g, "&gt;");
                s = s.replace(/ /g, "&nbsp;");
                s = s.replace(/\'/g, "&#39;");
                s = s.replace(/\"/g, "&quot;");
                s = s.replace(/\n/g, "<br/>");
                return s;
            },
            detailsClick(item) {
                this.$router.replace({
                    path: 'details?id=' + item.id
                })
                this.$router.go(0);
            },
            bookreviewClick() {
                this.$router.push({
                    path: '/bookreview?id=' + this.id
                })
            },
            evaluationClick() {
                this.$router.push({
                    path: '/evaluation?id=' + this.id
                })
            },
            directoryClick() {
                this.$router.push({
                    path: '/directory?id=' + this.id
                })
            },
            readingClick() {
                this.$router.push({
                    path: '/reading?id=' + this.id
                })
            }
        }
    }
</script>

<style scoped>
    .reading-title_active {
        color: #4D77FD !important;
    }

    .comments-list-no {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 28px;
        color: #303133;
    }

    .text_active {
        color: #4D77FD !important;
    }

    .comments-div-text {
        font-size: 20px;
        color: #BFC2CC;
        display: flex;
        align-items: center;
    }

    .reading-fixed {
        height: 100px;
    }

    .reading-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 40px;
        background: #fff;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        box-shadow: 0px 0px 18px 0px rgba(132, 134, 156, 0.1);
    }

    .reading-title {
        display: flex;
        align-items: center;
    }

    .reading {
        width: 340px;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 30px;
        background: linear-gradient(90deg, #7398ff, #4f77fb);
        border-radius: 10px;
    }

    .Plus_icon {
        width: 33px;
        height: 28px;
        margin-right: 10px;
    }

    .book-title {
        font-size: 26px;
        color: #303133;
        width: 145px;
        height: 27px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 20px;
    }

    .book-button-div {
        position: relative;
        margin-right: 30px;
        margin-top: 40px;
    }

    .book-button-div:nth-of-type(4n+4) {
        margin-right: 0 !important;
    }

    .book_img1 {
        width: 145px;
        height: 203px;
        border-radius: 10px;
        display: inline-block;
        background-size: cover;
        background: url("../../assets/images/book_cover_def.png") no-repeat;
    }

    .book-button {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 50px;
    }

    .like-right {
        color: #353B47;
        display: flex;
        align-items: center;
        font-size: 24px;
    }

    .like-title {
        color: #353B47;
        font-size: 34px;
    }

    .like {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 40px 40px 0 40px;
    }

    .Switch_icon {
        width: 30px;
        height: 24px;
        margin-left: 10px;
    }

    .comments-button {
        color: #303133;
        font-size: 28px;
        text-align: center;
        padding: 30px 0 0 0;
    }

    .comments-swiper {
        margin-left: 40px;
    }

    .comments-time {
        color: #BFC2CC;
        font-size: 20px;
    }

    .icon-img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
    }

    .comments-text {
        color: #303133;
        font-size: 28px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /*决定哪一行截取*/
        -webkit-box-orient: vertical;
        margin: 40px 0 40px 0;
    }

    .comments-list-title {
        color: #98664A;
        font-size: 24px;
    }

    .comments-list {
        display: flex;
        background: rgba(246, 250, 255, 1);
        border-radius: 10px;
        padding: 40px 20px;
        margin-right: 40px;
        height: 265px;
    }

    .comments-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        width: 100%;
    }

    .head {
        width: 60px;
        height: 60px;
        border-radius: 100%;
        margin-right: 20px;
        display: inline-block;
        background: url("../../assets/images/set_def_tx.png") no-repeat;
        background-size: cover;
    }

    .comments-top-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .comments-blue {
        color: #4D77FD;
        font-size: 24px;
    }

    .Write_icon {
        width: 24px;
        height: 24px;
    }

    .comments-top {
        display: flex;
        align-items: center;
        padding: 40px;
    }

    .comments-border {
        margin-top: 40px;
        border-top: 20px solid #F2F5F8;
    }

    .chapter-right {
        display: flex;
        align-items: center;
    }

    .chapter-left-time {
        color: #BFC2CC;
        font-size: 24px;
        margin-right: 18px;
    }

    .chapter-left-title {
        color: #353B47;
        font-size: 34px;
        margin-right: 18px;
    }

    .chapter-list {
        padding: 20px 40px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .sentiment-type {
        padding: 5px 15px;
        background: rgba(246, 250, 255, 1);
        border-radius: 4px;
        color: #4D77FD;
        font-size: 20px;
        margin-right: 10px;
    }

    .sentiment-type-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 40px;
    }

    .sentiment-text {
        font-size: 26px;
        font-weight: 400;
        color: rgba(96, 98, 102, 1);
        line-height: 42px;
        /*overflow: hidden;*/
        /*text-overflow: ellipsis;*/
        /*display: -webkit-box;*/
        /*-webkit-line-clamp: 5; !*决定哪一行截取*!*/
        /*-webkit-box-orient: vertical;*/
    }

    .Stars_icon {
        width: 21px;
        height: 21px;
        margin-left: 10px;
    }

    .subtitle-right-title {
        font-size: 30px;
        color: rgba(252, 173, 15, 1);
    }

    .subtitle-right {
        flex: 4;
        text-align: right;
    }

    .sentiment-subtitle {
        font-size: 22px;
        color: #BFC2CC;
        margin-top: 5px;
    }

    .number {
        color: #0A1C33;
        font-size: 40px;
    }

    .sentiment-title {
        color: #0A1C33;
        font-size: 28px;
    }

    .sentiment {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 40px;
    }

    .details-relative {
        position: relative;
    }

    .img-button {
        height: 45px;
        width: 100%;
        position: absolute;
        bottom: -35px;
    }

    .subtitle {
        color: #BFC2CC;
        font-size: 24px;
        margin-top: 79px;
    }

    .title {
        color: #0A1C33;
        font-size: 36px;
        margin-bottom: 58px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 447px;
    }

    .blue {
        color: #4D77FD;
        font-size: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 280px;
    }

    .Author_icon1 {
        display: flex;
        align-items: center;
    }

    .Author_icon {
        width: 20px;
        height: 23px;
        margin-right: 10px;
    }

    .details-top {
        display: flex;
        align-items: center;
        margin: 10px 40px 0;
    }

    .book_img {
        width: 190px;
        height: 266px;
        border-radius: 10px;
        display: inline-block;
        background: url("../../assets/images/book_cover_def.png") no-repeat;
        background-size: cover;
        margin-right: 24px;
    }
</style>